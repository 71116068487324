import React from 'react'
import Team from './team'
import PortableText from '../../serializers/portableText'
import Container from '../../container'
import styles from '../page.module.css'

const Page = ({title, _rawBody, team}) => {
  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          <Team team={team} />

        </Container>
      </article>
    </>
  )
}
export default Page
