import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Page from '../components/page/teamPage/index'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityTeamPage(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      hero {
        __typename
        ... on SanityMainImage {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 1900) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanitySlideshow {
          _key
          _type
          slides {
            _key
            altText
            slideImage {
              asset {
                fluid(maxWidth: 1900) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          videoType
          videoID
        }
      }
      people {
        _key
        fullName
        title
        email
        phone
        bio
        mainImage {
          asset {
            fluid(maxWidth: 277) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      seoSettings {
                  title
                  description
                  openGraphImage {
                    asset {
                      url
            }
         }
      }
    }
  }
`

const TeamPage = ({data: {sanityTeamPage: page}}) => (
  <Layout hero={page.hero} title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <Page
      title={page.title}
      _rawBody={page._rawBody}
      team={page.people}
    />
  </Layout>
)

export default TeamPage
