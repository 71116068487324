import React from 'react'
import Img from 'gatsby-image'
import Call from '../../../images/icons/call.svg'
import Email from '../../../images/icons/email.svg'
import styles from './team.module.css'

const Awards = ({team}) => {
  // console.log({team})
  return (
    <>
      <div className={styles.teamWrapper}>
        {team
          .map(person => (
            <div key={person._key} className={styles.personWrapper1}>
              <div>
                <h3 className={styles.personName}>{person.fullName}</h3>
                <p className={styles.personTitle}>{person.title}</p>
              </div>
              <div className={styles.personWrapper}>
                <div>
                  {person.mainImage && (<div className={styles.personImage}><Img fluid={person.mainImage.asset.fluid} alt={person.mainImage.alt} /> </div>)}
                  {person.email && (<p className={styles.personEmail}><a href={person.email} title={`Email ${person.fullName}`}><Email /></a></p>)}
                  {person.phone && (<p className={styles.personPhone}><a href={person.phone} title={`Call ${person.fullName}`}><Call /> {`Call ${person.phone.replace('tel:', '')}`}</a></p>)}
                </div>
                <div>
                  {person.bio && (<p className={styles.personBio}>{person.bio}</p>)}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default Awards
